/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .MediumManagerBody > form > div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
    }

    .MediumManagerBody > form > div > label{
        width: 50%;
        text-align: center;
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .MediumManagerBody > form > div{
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }

    .MediumManagerBody > form > div > label{
        width: 20%;
    }

    .MediumManagerInputFile{
        margin-right: 8%;
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .MediumManagerBody > form > div{
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }

    .MediumManagerBody > form > div > label{
        width: 20%;
    }

    .MediumManagerInputFile{
        margin-right: 8%;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .MediumManagerBody > form > div{
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }

    .MediumManagerBody > form > div > label{
        width: 20%;
    }

    .MediumManagerInputFile{
        margin-right: 8%;
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */
    .MediumManagerBody > form > div{
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }

    .MediumManagerBody > form > div > label{
        width: 20%;
    }

    .MediumManagerInputFile{
        margin-right: 8%;
    }
}

.MediumManagerBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.MediumManagerBody > form{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.MediumManagerBody > form *{
    font-size: 18px;
}

/* all fields with text width */
.MediumManagerBody > form > div > input, .MediumManagerBody > form > div > textarea, .MediumManagerBody > form > div > select{
    width: 80%;
    border-radius: 50px;
    background-color: #bcbcbc;
    padding: 10px 50px;
    color: #f3f6f4;
}

.MediumManagerBody > form > div > textarea{
    height: 40vh;
}

.MediumManagerBody > form > div > select{
    padding-right: 12.5%;
    text-align: center;
}

.MediumManagerInputFile{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.MediumManagerInputFile div{
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MediumManagerBody > form > .ButtonDiv {
    display: flex;
    justify-content: center;
    width: 100%;
}

.ButtonDiv  button{
    padding: 10px 0;
    border-radius: 50px;
    background-color: #2ecc71;
    color: white;
    width: 40%;
}

.ButtonDiv  button:hover{
    background-color: #57d68d;
}

.ButtonDiv  button:active{
    background-color: #24a35a;
    color: white;
}

/* media size */

.MediumManagerBody > form  video, .MediumManagerBody > form  img{
    max-width: 80%;
}