/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .menuItems {
        height: 7vh;
    }

    .textMenuItem {
        display: None;
    }

    .menuItem {
        font-size: 16px;
    }

    .userConnexion {
        width: 15%;
    }

    .userConnexion .authenticationDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .menuItems {
        height: 10vh;
    }

    .textMenuItem {
        display: None;
    }

    .menuItem {
        font-size: 16px;
    }

    .userConnexion {
        width: 15%;
    }

    .userConnexion .authenticationDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .menuItems {
        height: 7vh;
    }


    .iconMenuItem{
        display: None;
    }

    .menuItem {
        font-size: 18px;
    }

    .userConnexion {
        width: 10%;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .menuItems {
        height: 14vh;
    }


    .iconMenuItem{
        display: None;
    }

    .menuItem {
        font-size: 18px;
    }

    .userConnexion {
        width: 10%;
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */
    .menuItems {
        height: 7vh;
    }

    .iconMenuItem{
        display: None;
    }

    .menuItem {
        font-size: 18px;
    }

    .userConnexion {
        width: 7%;
    }
    
    .userConnexion .authenticationDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

.menu {
    display: flex;
    justify-content: space-between;
    background-color: var(--bg-menu);
    position: var(--menu-position);
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 60px;
}

.menuItems {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    width: 50%;
    height: 100%;
}

.menuItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    margin: 0 10px;
    padding: 5px 10px;
}

.menuItems a:hover{
    background-color: var(--bg-menu-option-hover);
    transition: 0.2s;
}

.menuItems a:active{
    background-color: var(--bg-menu-option-active);;
}

.menuItems a {
    color: var(--text-color);
    /* color: black; */
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.userConnexion {
    display: flex;
    justify-content: center;
    align-items: center;
}

.userConnexion:hover{
    background-color: var(--bg-menu-option-hover);
    cursor: pointer;
    transition: 0.5s;
}

.userConnexion:active{
    background-color: var(--bg-menu-option-active);
}

.userConnexion a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userConnexion * {
    font-size: 24px;
    color: var(--user-color);
}

.selected {
    background-color: var(--bg-menu-option-selected);
    border-bottom: var(--menu-border-botton);
}

.floatingButton{
    position: fixed;
    bottom: 50px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    z-index: 5;
  }
  
  .floatingButton:hover {
    background-color: #0056b3;
  }
  
  /* Menu des thèmes */
  .themeMenu {
    position: fixed;
    bottom: 80px;
    right: 20px;
    background: #007bff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: scale(0);
    transform-origin: bottom right;
    transition: transform 0.2s ease-out;
  }
  
  .themeMenu.show {
    transform: scale(1);
  }
  
  /* Boutons de sélection de thème */
  .themeOption {
    width: 400px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #32abeb;
  }
  
  .themeOption:hover {
    background-color: #7dc5ec;
  }

  @media (max-width: 768px) {

    .themeMenu.show {
        width: 80%;
    }

    .themeOption {
        width: 100%; /* Occupe toute la largeur de l'écran lorsqu'il est ouvert */
        height: 50px;
    }
  }
