/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .projectListBody{
        width: 70%;
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .projectListBody{
        width: 50%;
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .projectListBody{
        width: 70%;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .projectListBody{
        width: 50%;
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */
    .projectListBody{
        width: 50%;
    }
}

.projectListBody{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectListBody button{
    font-size: 18px;
    padding: 10px 0;
    margin-bottom: 10%;
    border-radius: 50px;
    background-color: #2ecc71;
    color: white;
    width: 40%;
}

.projectListBody button:hover{
    background-color: #57d68d;
}

.projectListBody button:active{
    background-color: #24a35a;
    color: white;
}

.projectList {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100%;
}

.project {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0;

}

.project a{
    text-align: center;
    margin-left: 3%;
    padding: 10px;
    border-radius: 50px;
    text-decoration: none;
    font-size: 18px;
    background-color: #bcbcbc;
    color: black;
    width: 90%;
}

.project a:hover{
    background-color: #c9c9c9;
}

.project a:active{
    background-color: #969696;
    color: white;
}

.project svg {
    margin-left: 10px;
    color: red;
    cursor: pointer;
}

.project svg:hover{
    color: #ff7f7f;
}

.project svg:active{
    color: #cc0000;
}