/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .bodyMenu {
        width: 70%;
    }

    .bodyMenuItem * {
        margin: 0 10px;
        font-size: 20px;
    }

    .homeProContent {
        font-size: 20px;
    }

    
    .homeProContent > section > span {
        text-align: center;
    }

    .SectionContainerOdd {
        flex-direction: column-reverse;
    }
    
    .SectionContainerEven {
        flex-direction: column;
    }
    
    .homeSectionText {
        padding: 0;
        width: 100%;
    }

    .SectionContainer img {
        width: 100%;
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .bodyMenu {
        width: 50%;
    }

    .homeProContent {
        font-size: 20px;
    }

    .homeProContent > section > span {
        text-align: center;
    }

    .SectionContainerOdd {
        flex-direction: column-reverse;
    }
    
    .SectionContainerEven {
        flex-direction: column;
    }
    
    .homeSectionText {
        padding: 0;
        width: 100%;
    }

    .SectionContainer img {
        width: 100%;
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .bodyMenu {
        width: 70%;
    }

    .homeProContent {
        font-size: 24px;
    }

    .homeProContent > section > span {
        text-align: center;
    }

    .SectionContainerOdd {
        flex-direction: column-reverse;
    }
    
    .SectionContainerEven {
        flex-direction: column;
    }
    
    .homeSectionText {
        padding: 0;
        width: 100%;
    }

    .SectionContainer img {
        width: 100%;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .bodyMenu {
        width: 50%;
    }

    .homeProContent {
        font-size: 24px;
    }

    .homeProContent > section > span {
        text-align: center;
    }

    .SectionContainerOdd {
        flex-direction: column-reverse;
    }
    
    .SectionContainerEven {
        flex-direction: column;
    }
    
    .homeSectionText {
        padding: 0;
        width: 100%;
    }

    .SectionContainer img {
        width: 100%;
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */ 
    .bodyMenu {
        width: 50%;
    }

    .bodyMenuItem * {
        margin: 0 20px;
        font-size: 24px;
    }

    .homeProContent {
        font-size: 24px;
    }

    .homeSectionText {
        padding: 40px;
        width: 50%;
    }

    .SectionContainer img {
        width: 40%;
    }
}

.homeBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-top: var(--body-margin-top);
}
/* for original version  */
.bodyMenu {
    list-style: none;
    padding: 0;
    display: var(--body-menu-display);
}

.bodyMenuItem {
    margin: 20px 0;
    padding: 15px 0;
    border-radius: 50px;
    background-color: #0097e6;
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
}

.bodyMenuItem:hover{
    background-color: #32abeb;
}

.bodyMenuItem:active{
    background-color: #0069a1;
}

.bodyMenuItem svg {
    margin-left: 10%;
    color: white;
}

.bodyMenuItem a {
    width: 90%;
    display: flex;
    justify-content: center;
    margin-right: 15%;
    color: white;
    text-decoration: none;
}

/* for pro version  */

.homeProContent {
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    display: var(--home-pro-display);
    color: var(--home-body-color);
}

.homeProContent > section > span {
    font-size: 24px;
    color: var(--text-color);
    font-weight: 800;
}

.homeProContent > section {
    margin: 40px 0;
}

hr {
    border: solid 0.5px var(--hr-color);
}

.SectionContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 20px;
    background-color: var(--section-container-background-color);
    box-shadow: var(--section-container-box-shadow);
    border-radius: 20px;
}

.homeSectionTextNoImg {
    text-align: justify;
    font-size: 20px;
}

.homeSectionText {
    font-size: 20px;
    gap: 10px;
    text-align: justify;
}

.homeSectionText ul li{
    margin: 15px 0;
}
