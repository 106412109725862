.App{
    min-height: 100vh;
    background: var(--bg-1);
    background: var(--bg-2);
    display: flex;
    flex-direction: column;
    font-family: Gill Sans, sans-serif;
}

h1{
    text-align: center;
    color: var(--text-color);
}
