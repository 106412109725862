/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .contactMenu {
        width: 70%;
    }

    .contactMenuItem * {
        margin: 0 10px;
        font-size: 20px;
    }

    .contactMenuItem a {
        margin-left: 22%;
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .contactMenu {
        width: 50%;
    }

    .contactMenuItem * {
        margin: 0 10px;
        font-size: 20px;
    }

    .contactMenuItem a {
        margin-left: 30%;
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .contactMenu {
        width: 70%;
    }

    .contactMenuItem * {
        margin: 0 20px;
        font-size: 24px;
    }

    .contactMenuItem a {
        margin-left: 35%;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .contactMenu {
        width: 50%;
    }

    .contactMenuItem * {
        margin: 0 20px;
        font-size: 24px;
    }

    .contactMenuItem a {
        margin-left: 35%;
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */ 
    .contactMenu {
        width: 50%;
    }

    .contactMenuItem * {
        margin: 0 20px;
        font-size: 24px;
    }

    .contactMenuItem a {
        margin-left: 35%;
    }
}

.contactBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-top: var(--body-margin-top);
}

.contactMenu {
    list-style: none;
    padding: 0;
}

.contactMenuItem {
    margin: 20px 0;
    padding: 15px 0;
    border-radius: 50px;
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
}

.contactMenuItem svg {
    color: white;
}

.contactMenuItem a {
    color: white;
    text-decoration: none;
    display: flex;
}

.linkedin {
    background-color: #0e76a8;
}

.linkedin:hover {
    background-color: #258dbf;
}

.linkedin:active{
    background-color: #185a7a;
}

.instagram {
    background-color: #833ab4;
    background: linear-gradient(to right, #833ab4, #fd1d1d, #fcb045);
}

.instagram:hover {
    background-color: #e95950;
    background: linear-gradient(to right, #e95950, #e95950, #fcb045);
}

.instagram:active {
    background-color: #6a1a87;
    background: linear-gradient(to right, #833ab4, #fd1d1d, #fcb045);
}

.facebook {
    background-color: #3b5998;
}

.facebook:hover {
    background-color: #5874af;
}

.facebook:active {
    background-color: #374a72;
}

.malt {
    background-color: #ff5d56;
}

.malt:hover {
    background-color: #f7b6b4;
}

.malt:active {
    background-color: #e7312a;
}

.comeup {
    background-color: #ffee66;
}

.comeup:hover {
    background-color: #f9f3c6;
}

.comeup:active {
    background-color: #e9d535;
}

.comeup a, .comeup svg {
    color:black;
}

.fiverr {
    background-color: #1dbf73;
}

.fiverr:hover {
    background-color: #44cf8e;
}

.fiverr:active {
    background-color: #248c5b;
}

.superprof {
    background-color: #ff6363;
}

.superprof:hover {
    background-color: #f8c2c2;
}

.superprof:active {
    background-color: #e83333;
}