/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .aboutImg img{
        width: 40%;
    }

    .aboutBody {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .aboutPart {
        margin: 0 10px;
        font-size: 18px;
    }

    .contactMenuItem a {
        margin-left: 22%;
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .aboutImg img{
        width: 40%;
    }

    .aboutBody {
        width: 50%;
    }

    .aboutPart {
        margin: 0 10px;
        font-size: 14px;
    }

    .contactMenuItem a {
        margin-left: 30%;
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .aboutImg img{
        width: 40%;
    }

    .aboutBody {
        width: 70%;
    }

    .aboutPart {
        margin: 0 20px;
        font-size: 18px;
    }

    .contactMenuItem a {
        margin-left: 35%;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .aboutImg img{
        width: 40%;
    }


    .aboutBody {
        width: 50%;
    }

    .aboutPart {
        margin: 0 20px;
        font-size: 18px;
    }

    .contactMenuItem a {
        margin-left: 35%;
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */ 
    .aboutImg img{
        width: 20%;
    }

    .aboutBody {
        width: 50%;
    }

    .aboutPart * {
        font-size: 18px;
    }

    .contactMenuItem a {
        margin-left: 35%;
    }
}

.aboutPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-top: var(--body-margin-top);
}

.aboutImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.aboutImg img{
    margin: 0 30px;
    display: flex;
}

h2 {
    color: var(--text-color);
}

.aboutPart {
    margin: 20px 0;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 50px;
    background-color: var(--about-cards-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: var(--about-box-shadow);
}

p {
    margin: 0.2em 0;
}

.firstLine {
    font-weight: bolder;
}

.hello {
    color: #ffd966;
}

.relevant {
    color: var(--about-relevant-color);
    font-style: italic;
}

.aboutPart a {
    color: #ffd966;
    text-decoration:underline;
}