/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .articleBody {
        width: 70%;
        font-weight: 600;
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .articleBody {
        width: 50%;
        font-weight: 600;
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .articleBody {
        width: 50%;
        font-weight: 500;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .articleBody {
        width: 50%;
        font-weight: 500;
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */ 
    .articleBody {
        width: 50%;
        font-weight: 500;
    }
}

.articlePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-top: var(--body-margin-top);
}

.articleBody {
    white-space: pre-wrap;
    font-size: 18px;
    color: #ccead0;
    text-align: justify;
    /* (2024-03-04 commented (Ornement conflicts)) */
    /* text gradient : https://unused-css.com/blog/css-text-gradient */
    /* background: linear-gradient(70deg, rgba(204,234,208,1) 0%, rgba(251,225,175,1) 100%); */
    /* -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; */
    /* end text gradient */
}

.articleBody pre {
    background: #f4f4f4;border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}

.articleBody img {
    max-width: 100%;
}


.articleMendium {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}
.articleMendium div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.articleMendium div img{
    width: 50%;
}

.articleMendium div  video{
    max-width: 80%;
}