/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .banner img{
        width: 70vw;
    }

    .informations {
        height: 7vh;
        width: 70vw;
    }

    .informations span {
        width: 80%;
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .banner img{
        width: 50vw;
    }

    .informations {
        height: 14vh;
        width: 50vw;
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .banner img{
        width: 70vw;
    }

    .informations {
        height: 7vh;
        width: 70vw;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .banner img{
        width: 50vw;
    }

    .informations {
        height: 14vh;
        width: 50vw;
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */
    .banner img{
        width: 50vw;
    }

    .informations {
        height: 10vh;
        width: 50vw;
    }
}

.articleListBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    margin-top: var(--body-margin-top);
}

.articleList {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
}

.article {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    position: relative;
}

.banner img{
    aspect-ratio: 16 / 9;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.banner img:hover{
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.informations {
    position: absolute;
    bottom: 3px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    color: white;
    text-align: center;
}
