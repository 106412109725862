footer {
    background: #111;
    color: #fff;
    text-align: center;
    padding: 20px 0;
}

.footerContainer {
    max-width: 1200px;
    margin: auto;
    padding: 10px;
}

.footerLogo h2 {
    font-size: 24px;
    font-weight: bold;
    color: #d6eefb;
}

.footerLinks {
    margin: 15px 0;
}

.footerLinks a {
    color: #ccc;
    text-decoration: none;
    margin: 0 15px;
    transition: 0.3s;
}

.footerLinks a:hover {
    color: #fff;
}

.footerSocials {
    margin: 15px 0;
}

.footerSocials a {
    color: #ccc;
    margin: 0 10px;
    font-size: 20px;
    transition: 0.3s;
}

.footerSocials a:hover {
    color: #fff;
}

.footerText {
    font-size: 14px;
    margin-top: 10px;
}