/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .banner img{
        width: 70vw;
    }

    .informations {
        height: 7vh;
        width: 70vw;
    }

    .informations span {
        width: 80%;
    }

    .slideShow {
        width: 70vw;
    }

    @keyframes slide {
        8% {margin-left: 0px;}
        16% {margin-left: -70vw;}
        25% {margin-left: -70vw;}
        33% {margin-left: -140vw;}
        42% {margin-left: -140vw;}
        50% {margin-left: -210vw;}
        59% {margin-left: -210vw;}
        67% {margin-left: -280vw;}
        76% {margin-left: -280vw;}
        84% {margin-left: -350vw;}
        93% {margin-left: -350vw;}
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .banner img{
        width: 50vw;
    }

    .informations {
        height: 14vh;
        width: 50vw;
    }

    .slideShow {
        width: 50vw;
    }

    @keyframes slide {
        8% {margin-left: 0px;}
        16% {margin-left: -50vw;}
        25% {margin-left: -50vw;}
        33% {margin-left: -100vw;}
        42% {margin-left: -100vw;}
        50% {margin-left: -150vw;}
        59% {margin-left: -150vw;}
        67% {margin-left: -200vw;}
        76% {margin-left: -200vw;}
        84% {margin-left: -250vw;}
        93% {margin-left: -250vw;}
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .banner img{
        width: 70vw;
    }

    .informations {
        height: 7vh;
        width: 70vw;
    }

    .slideShow {
        width: 70vw;
    }

    @keyframes slide {
        8% {margin-left: 0px;}
        16% {margin-left: -70vw;}
        25% {margin-left: -70vw;}
        33% {margin-left: -140vw;}
        42% {margin-left: -140vw;}
        50% {margin-left: -210vw;}
        59% {margin-left: -210vw;}
        67% {margin-left: -280vw;}
        76% {margin-left: -280vw;}
        84% {margin-left: -350vw;}
        93% {margin-left: -350vw;}
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .banner img{
        width: 50vw;
    }

    .informations {
        height: 14vh;
        width: 50vw;
    }

    .slideShow {
        width: 50vw;
    }

    @keyframes slide {
        8% {margin-left: 0px;}
        16% {margin-left: -50vw;}
        25% {margin-left: -50vw;}
        33% {margin-left: -100vw;}
        42% {margin-left: -100vw;}
        50% {margin-left: -150vw;}
        59% {margin-left: -150vw;}
        67% {margin-left: -200vw;}
        76% {margin-left: -200vw;}
        84% {margin-left: -250vw;}
        93% {margin-left: -250vw;}
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */
    .banner img{
        width: 50vw;
    }

    .informations {
        height: 10vh;
        width: 50vw;
    }

    .slideShow {
        width: 50vw;
    }

    @keyframes slide {
        8% {margin-left: 0px;}
        16% {margin-left: -50vw;}
        25% {margin-left: -50vw;}
        33% {margin-left: -100vw;}
        42% {margin-left: -100vw;}
        50% {margin-left: -150vw;}
        59% {margin-left: -150vw;}
        67% {margin-left: -200vw;}
        76% {margin-left: -200vw;}
        84% {margin-left: -250vw;}
        93% {margin-left: -250vw;}
      }
}

.slideShow {
    aspect-ratio: 16 / 9;
    text-align: center;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50px;
}

.slideshowContainer {
    display: flex;
    animation: slide 30s ease infinite;
    border-radius: 50px;
}

.slide {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 50px;
}

.banner img{
    aspect-ratio: 16 / 9;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.banner img:hover{
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    transition: 0.5s;
}

.informations {
    position: absolute;
    bottom: 3px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    color: white;
    text-align: center;
}
