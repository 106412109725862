/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .loginFormDiv{
        height: 70vh;
    }

    .loginForm{
        width: 80vw;
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .loginForm{
        width: 40vw;
        margin-bottom: 50px;

    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .loginFormDiv{
        height: 80vh;
    }

    .loginForm{
        width: 40vw;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .loginForm{
        width: 35vw;
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */ 
    .loginFormDiv{
        height: 80vh;
    }
    
    .loginForm{
        width: 35vw;
    }
}

.loginBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-top: var(--body-margin-top);
}

.badCredentials {
    color: red;
    font-weight: bold;
}

.loginFormDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginForm{
    padding: 10px;
    background-color: #eeeeee;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
}

.loginForm div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.loginForm div *{
    margin: 10px;
}

.loginLabel{
    font-size: 18px;
    font-weight: bold;
}

.loginInput{
    border-radius: 50px;
    background-color: #bcbcbc;
    padding: 10px 50px;
    text-align: center;
}

.loginForm button{
    margin: 20px;
    padding: 10px 0;
    border-radius: 50px;
    background-color: #2ecc71;
    color: white;
    font-size: 18px;
}

.loginForm button:hover{
    background-color: #57d68d;
}

.loginForm button:active{
    background-color: #24a35a;
    color: white;
}