/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .popupBody {
        left: 10%;
        right: 10%;
        top: 30%;
    }

    .popupBody h1 {
        font-size: 20px;
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .popupBody {
        left: 30%;
        right: 30%;
        top: 30%;
    }

    .popupBody h1 {
        font-size: 20px;
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .popupBody {
        left: 30%;
        right: 30%;
        top: 30%;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .popupBody {
        left: 30%;
        right: 30%;
        top: 30%;
    }
}

/* PC/Desktop */
@media (min-width: 1024px) {
    /* Your CSS styles for PC/Desktop */
    .popupBody {
        left: 30%;
        right: 30%;
        top: 30%;
    }
}

.popupContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.popupBody {
    position: absolute;
    text-align: center;
    margin: auto;
    border-radius: 15px;
    border-color: black;
    background: #bcbcbc;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.popupButtonsDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupContainer .popupButton {
    border-radius: 50px;
    font-size: 18px;
    padding: 10px 0;
    background-color: #0097e6;
    margin-bottom: 10px;
    width: 40%;
}

.popupContainer .popupButton:hover{
    background-color: #32abeb;
}

.popupContainer .popupButton:active{
    background-color: #0069a1;
    color: white;
}