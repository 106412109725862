/* Mobile in Portrait */
@media (max-width: 767px) and (orientation: portrait) {
    /* Your CSS styles for mobile in portrait orientation */
    .mediumListBody{
        width: 95%;
    }

    .banner img{
        width: 70vw;
    }

    .informations {
        height: 7vh;
        width: 70vw;
    }

    .informations span {
        width: 80%;
        font-size: 15px;
    }

    .medium {
        width: 60%;
    }
}

/* Mobile in Landscape */
@media (max-width: 767px) and (orientation: landscape) {
    /* Your CSS styles for mobile in landscape orientation */
    .mediumListBody{
        width: 95%;
    }

    .banner img{
        width: 35vw;
    }

    .informations {
        height: 14vh;
        width: 35vw;
    }

    .informations span {
        font-size: 15px;
    }

    .medium {
        width: 50%;
    }
}

/* Tablet in Portrait */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    /* Your CSS styles for tablet in portrait orientation */
    .mediumListBody{
        width: 95%;
    }

    .banner img{
        width: 40vw;
    }

    .informations {
        height: 7vh;
        width: 40vw;
    }
}

/* Tablet in Landscape */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* Your CSS styles for tablet in landscape orientation */
    .mediumListBody{
        width: 95%;
    }

    .banner img{
        width: 25vw;
    }

    .informations {
        height: 14vh;
        width: 25vw;
    }

    .informations span {
        font-size: 12px;
    }
}

/* PC/Desktop */
@media (min-width: 1025px) {
    /* Your CSS styles for PC/Desktop */
    .mediumListBody{
        width: 95%;
    }

    .banner img{
        width: 25vw;
    }

    .informations {
        height: 8vh;
        width: 25vw;
    }
    .informations span {
        font-size: 15px;
    }

    .medium {
        width: 30%;
    }
}

.mediumListBody{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mediumListBody button{
    font-size: 18px;
    padding: 10px 0;
    margin-bottom: 10%;
    border-radius: 50px;
    background-color: #2ecc71;
    color: white;
    width: 40%;
}

.mediumListBody button:hover{
    background-color: #57d68d;
}

.mediumListBody button:active{
    background-color: #24a35a;
    color: white;
}

.mediumList {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
}

.medium {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    position: relative;
}

.medium a{
    text-align: center;
    border-radius: 50px;
    text-decoration: none;
    font-size: 18px;
    color: black;
}

.medium .redIcon,  .medium .blankIcon{
    margin-left: 10px;
}

.medium .redIcon {
    color: red;
    cursor: pointer;
}

.medium .redIcon:hover{
    color: #ff7f7f;
}

.medium .redIcon:active{
    color: #cc0000;
}

.medium .blankIcon {
    color: #fff2cc;
    cursor: pointer;
}

.medium .blankIcon:hover{
    color: #ffffff
    ;
}

.medium .blankIcon:active{
    color: #e5d9b7;
}

.banner img{
    aspect-ratio: 16 / 9;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: rgba(100, 171, 235, 0.5);
    box-shadow: rgba(9, 30, 66, 0.75) 0px 4px 8px -2px, rgba(9, 30, 66, 0.28) 0px 0px 0px 1px;
}

.banner img:hover{
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.informations {
    position: absolute;
    bottom: 3px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    color: white;
    text-align: center;
}

.action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.action svg {
    margin: 5px 0;
}